<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3204_531)">
      <path
        d="M8.93723 1.75745C8.76418 1.93055 8.66696 2.1653 8.66696 2.41006C8.66696 2.65483 8.76418 2.88957 8.93723 3.06268C9.51815 3.6436 9.91376 4.38375 10.074 5.18951C10.2343 5.99528 10.152 6.83047 9.83764 7.58949C9.52325 8.3485 8.99084 8.99724 8.30774 9.45367C7.62465 9.91009 6.82155 10.1537 6 10.1537C5.17845 10.1537 4.37535 9.91009 3.69225 9.45367C3.00916 8.99724 2.47675 8.3485 2.16235 7.58949C1.84796 6.83047 1.76569 5.99528 1.92597 5.18951C2.08624 4.38375 2.48185 3.6436 3.06277 3.06268C3.23091 2.88858 3.32396 2.65541 3.32185 2.41338C3.31975 2.17136 3.22267 1.93984 3.05152 1.76869C2.88038 1.59755 2.64886 1.50047 2.40683 1.49836C2.16481 1.49626 1.93163 1.5893 1.75754 1.75745C0.918375 2.59654 0.34688 3.66564 0.115328 4.82954C-0.116224 5.99343 0.00256606 7.19986 0.456677 8.29624C0.910787 9.39263 1.67982 10.3297 2.66653 10.989C3.65323 11.6484 4.81329 12.0003 6 12.0003C7.18671 12.0003 8.34676 11.6484 9.33347 10.989C10.3202 10.3297 11.0892 9.39263 11.5433 8.29624C11.9974 7.19986 12.1162 5.99343 11.8847 4.82954C11.6531 3.66564 11.0816 2.59654 10.2425 1.75745C10.0694 1.5844 9.83461 1.48718 9.58984 1.48718C9.34507 1.48718 9.11033 1.5844 8.93723 1.75745Z"
        fill="#EF4444"
      />
      <path
        d="M5.99925 5.90769C6.24369 5.90647 6.47777 5.80883 6.65062 5.63598C6.82347 5.46314 6.92111 5.22905 6.92232 4.98461V0.923076C6.92232 0.678261 6.82507 0.443473 6.65196 0.270363C6.47885 0.0972523 6.24406 0 5.99925 0C5.75443 0 5.51965 0.0972523 5.34653 0.270363C5.17342 0.443473 5.07617 0.678261 5.07617 0.923076V4.98461C5.07739 5.22905 5.17503 5.46314 5.34788 5.63598C5.52072 5.80883 5.75481 5.90647 5.99925 5.90769Z"
        fill="#EF4444"
      />
    </g>
    <defs>
      <clipPath id="clip0_3204_531">
        <rect
          width="12"
          height="12"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <div class="logout-modal">
    <div class="logout-modal__container">
      <div class="logout-modal__title">Log out of your account?</div>
      <div class="logout-modal__btn_container">
        <button
          class="cancel-button"
          @click="$emit('close')"
        >
          Cancel
        </button>
        <button
          class="logout-button"
          @click="logout"
        >
          <span
            v-if="isLoading"
            class="loading-circle"
          ></span>
          <span v-else> Log Out</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useForm } from '@inertiajs/vue3';

export default {
  emits: ['close', 'logout'],
  props: {
    forceLogout: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: useForm({}),
      isLoading: false,
    };
  },
  methods: {
    logout() {
      this.isLoading = true;

      if (this.forceLogout) {
        this.form.post(route('logout'), {
          onFinish: () => {
            this.isLoading = false;
          },
        });
        this.$emit('close');
      } else {
        this.$emit('logout');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logout-modal {
    @apply min-h-screen w-screen fixed top-[0px] left-[0px] overflow-y-auto z-50 flex items-center justify-center;
    background: rgba(0, 0, 0, 0.9);
}

.logout-modal__container {
}

.logout-modal__title {
    @apply font-sans text-[32px] font-normal leading-[70px] tracking-[0px] text-center text-white;
}

.logout-modal__btn_container {
    @apply flex flex-row gap-[10px] justify-center mt-[30px];
}

.cancel-button {
    @apply font-sans w-[170px] h-[56px] text-[16px] font-normal leading-[26px] tracking-[0px] text-center text-white rounded-[16px];
    background-color: #191a1b;
    box-shadow: 0 0 4px 0 #00000018;
    &:hover {
        background: linear-gradient(270deg, #0fab6d 0%, #6c3da9 100%);
    }
}

.logout-button {
    @apply font-sans w-[170px] h-[56px] text-[16px] font-normal leading-[26px] tracking-[0px] text-center text-white rounded-[16px];
    background-color: #191a1b;
    box-shadow: 0 0 4px 0 #00000018;
    &:hover {
        background: linear-gradient(270deg, #0fab6d 0%, #6c3da9 100%);
    }
}
</style>

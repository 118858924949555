<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3204_528)">
      <path
        d="M11.3415 4.697L10.4015 4.5775C10.3249 4.34138 10.2297 4.11167 10.117 3.8905L10.6975 3.143C10.8109 2.99948 10.8674 2.81917 10.8562 2.63659C10.8449 2.45401 10.7667 2.282 10.6365 2.1535L9.849 1.366C9.72044 1.23502 9.54805 1.15611 9.3649 1.14439C9.18174 1.13267 9.00071 1.18898 8.8565 1.3025L8.11 1.883C7.8887 1.77018 7.65881 1.67505 7.4225 1.5985L7.303 0.66C7.28124 0.478182 7.19363 0.310633 7.05673 0.189024C6.91983 0.0674143 6.74312 0.000169553 6.56 0L5.44 0C5.0615 0 4.742 0.2835 4.697 0.6585L4.5775 1.5985C4.34111 1.67483 4.11121 1.76997 3.89 1.883L3.143 1.3025C2.9994 1.18929 2.81913 1.13293 2.63661 1.14418C2.45409 1.15543 2.28211 1.23351 2.1535 1.3635L1.366 2.1505C1.23489 2.27909 1.15589 2.45157 1.14417 2.63484C1.13245 2.81812 1.18883 2.99926 1.3025 3.1435L1.883 3.8905C1.77006 4.11157 1.67492 4.3413 1.5985 4.5775L0.66 4.697C0.2835 4.742 0 5.0615 0 5.44V6.56C0 6.9385 0.2835 7.258 0.6585 7.303L1.5985 7.4225C1.676 7.661 1.7715 7.891 1.883 8.1095L1.3025 8.857C1.18908 9.00052 1.13258 9.18083 1.14384 9.36341C1.1551 9.54599 1.23331 9.718 1.3635 9.8465L2.151 10.634C2.27974 10.7647 2.45213 10.8434 2.63522 10.855C2.81831 10.8666 2.99927 10.8104 3.1435 10.697L3.8905 10.1165C4.109 10.2285 4.339 10.324 4.5775 10.401L4.697 11.339C4.742 11.7165 5.0615 12 5.44 12H6.56C6.9385 12 7.258 11.7165 7.303 11.3415L7.4225 10.4015C7.65862 10.3249 7.88833 10.2297 8.1095 10.117L8.857 10.6975C9.1595 10.9325 9.5865 10.905 9.8465 10.6365L10.634 9.849C10.765 9.72044 10.8439 9.54805 10.8556 9.3649C10.8673 9.18174 10.811 9.00071 10.6975 8.8565L10.117 8.1095C10.229 7.891 10.3245 7.661 10.4015 7.4225L11.3395 7.303C11.5213 7.28124 11.6889 7.19363 11.8105 7.05673C11.9321 6.91983 11.9993 6.74312 11.9995 6.56V5.44C11.9996 5.25713 11.9327 5.08055 11.8115 4.94365C11.6902 4.80675 11.523 4.719 11.3415 4.697ZM6 8.5C4.6215 8.5 3.5 7.3785 3.5 6C3.5 4.6215 4.6215 3.5 6 3.5C7.3785 3.5 8.5 4.6215 8.5 6C8.5 7.3785 7.3785 8.5 6 8.5Z"
        fill="#0FAB6D"
      />
    </g>
    <defs>
      <clipPath id="clip0_3204_528">
        <rect
          width="12"
          height="12"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3217_3774)">
      <path
        d="M12 12.75C11.11 12.75 10.24 12.4861 9.49994 11.9916C8.75991 11.4971 8.18314 10.7943 7.84254 9.97208C7.50195 9.14981 7.41283 8.24501 7.58647 7.3721C7.7601 6.49918 8.18868 5.69736 8.81802 5.06802C9.44736 4.43869 10.2492 4.0101 11.1221 3.83647C11.995 3.66283 12.8998 3.75195 13.7221 4.09254C14.5443 4.43314 15.2471 5.00991 15.7416 5.74994C16.2361 6.48996 16.5 7.35999 16.5 8.25C16.5 9.44348 16.0259 10.5881 15.182 11.432C14.3381 12.2759 13.1935 12.75 12 12.75ZM12 5.25C11.4067 5.25 10.8266 5.42595 10.3333 5.75559C9.83994 6.08524 9.45543 6.55377 9.22836 7.10195C9.0013 7.65013 8.94189 8.25333 9.05765 8.83527C9.1734 9.41722 9.45912 9.95177 9.87868 10.3713C10.2982 10.7909 10.8328 11.0766 11.4147 11.1924C11.9967 11.3081 12.5999 11.2487 13.1481 11.0216C13.6962 10.7946 14.1648 10.4101 14.4944 9.91671C14.8241 9.42337 15 8.84335 15 8.25C15 7.45435 14.6839 6.69129 14.1213 6.12868C13.5587 5.56607 12.7957 5.25 12 5.25Z"
        fill="#0FAB6D"
      />
      <path
        d="M12.0005 23.2499C10.3373 23.2488 8.69507 22.8789 7.192 22.167C5.68893 21.455 4.36242 20.4186 3.30797 19.1324L2.91797 18.6524L3.30797 18.1799C4.36331 16.8954 5.69021 15.8607 7.19323 15.1504C8.69625 14.44 10.338 14.0715 12.0005 14.0715C13.6629 14.0715 15.3047 14.44 16.8077 15.1504C18.3107 15.8607 19.6376 16.8954 20.693 18.1799L21.083 18.6524L20.693 19.1324C19.6385 20.4186 18.312 21.455 16.8089 22.167C15.3059 22.8789 13.6636 23.2488 12.0005 23.2499ZM4.88297 18.6599C5.79515 19.6364 6.89832 20.415 8.12399 20.9473C9.34967 21.4796 10.6717 21.7543 12.008 21.7543C13.3442 21.7543 14.6663 21.4796 15.8919 20.9473C17.1176 20.415 18.2208 19.6364 19.133 18.6599C18.2208 17.6834 17.1176 16.9048 15.8919 16.3725C14.6663 15.8402 13.3442 15.5655 12.008 15.5655C10.6717 15.5655 9.34967 15.8402 8.12399 16.3725C6.89832 16.9048 5.79515 17.6834 4.88297 18.6599Z"
        fill="#0FAB6D"
      />
      <path
        d="M11.9997 23.25C9.44426 23.2517 6.96438 22.3834 4.96821 20.7878C2.97205 19.1923 1.57856 16.9648 1.0171 14.4718C0.455634 11.9788 0.759653 9.36891 1.87912 7.07172C2.99858 4.77453 4.86678 2.92691 7.17623 1.83296C9.48568 0.739009 12.0988 0.463918 14.5854 1.05296C17.072 1.642 19.284 3.06008 20.8573 5.07379C22.4306 7.0875 23.2714 9.57685 23.2414 12.1321C23.2114 14.6874 22.3123 17.1563 20.6922 19.1325C19.6377 20.4187 18.3112 21.4551 16.8082 22.1671C15.3051 22.879 13.6629 23.2489 11.9997 23.25ZM11.9997 2.25001C10.0713 2.25001 8.18627 2.82184 6.58289 3.89318C4.97951 4.96452 3.72983 6.48726 2.99187 8.26884C2.25392 10.0504 2.06084 12.0108 2.43704 13.9021C2.81325 15.7935 3.74185 17.5307 5.10541 18.8943C6.46897 20.2579 8.20625 21.1865 10.0976 21.5627C11.9889 21.9389 13.9493 21.7458 15.7309 21.0078C17.5124 20.2699 19.0352 19.0202 20.1065 17.4168C21.1779 15.8134 21.7497 13.9284 21.7497 12C21.7497 9.41415 20.7225 6.9342 18.894 5.10572C17.0655 3.27724 14.5856 2.25001 11.9997 2.25001Z"
        fill="#0FAB6D"
      />
      <path
        d="M3.88477 18.6601C3.88477 18.6601 11.4373 27.0976 19.1248 19.5001L20.1148 18.6601C20.1148 18.6601 13.6948 12.0001 7.17727 15.9976L3.88477 18.6601Z"
        fill="#0FAB6D"
      />
      <path
        d="M12 12C14.0711 12 15.75 10.3211 15.75 8.25C15.75 6.17893 14.0711 4.5 12 4.5C9.92893 4.5 8.25 6.17893 8.25 8.25C8.25 10.3211 9.92893 12 12 12Z"
        fill="#0FAB6D"
      />
    </g>
    <defs>
      <clipPath id="clip0_3217_3774">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<template>
  <div class="main-header__container">
    <div
      class="main-header"
      :class="showHeaderButton ? 'justify-between' : 'justify-center'"
    >
      <button
        class="main-header__logo"
        @click="$inertia.visit(route('chat.index'))"
      >
        <img
          alt="logo"
          width="48"
          height="48"
          src="../../../resources/images/logo-small.webp"
        />
        <span class="main-header__logo-title"> AskGPT </span>
      </button>
      <template v-if="showHeaderButton">
        <button
          v-if="!user && !user?.email"
          class="main-header__button"
          @click="getStartedButtonClicked"
        >
          Get Started
        </button>
        <button
          v-else
          class="main-header__profile-button"
          @click="isAuthMenuOpened = true"
          v-click-outside="closeAuthMenu"
        >
          <UserIcon />
          {{ user?.email }}
          <span
            v-if="isAuthMenuOpened"
            class="chat-header__auth-menu"
          >
            <a
              v-if="userIsAdmin"
              class="settings-container text-white hover:text-[#0FAB6D]"
              :href="route('prompt-sections.index')"
            >
              <span class="settings-container__title">
                Admin Panel
              </span>
            </a>
            <button
              class="settings-container text-white hover:text-[#0FAB6D]"
              @click="$inertia.visit(route('chat.index'))"
            >
              <img
                alt="logo"
                width="12"
                height="12"
                src="../../../resources/images/logo-small.webp"
              />
              <span class="settings-container__title">
                {{ $t('chat.burgerMenu.startConversation') }}
              </span>
            </button>
            <button
              class="settings-container text-white hover:text-[#0FAB6D]"
              @click="$inertia.visit(route('settings'))"
            >
              <SettingsiconSmall />
              <span class="settings-container__title">
                {{ $t('chat.burgerMenu.settings') }}
              </span>
            </button>
            <button
              class="settings-container text-white hover:text-[#EF4444]"
              @click="isLogoutModalOpened = true"
            >
              <LogoutIconSmall />
              <span class="settings-container__title">
                {{ $t('chat.burgerMenu.logout') }}
              </span>
            </button>
          </span>
        </button>
      </template>
    </div>
    <transition name="fade">
      <LogoutModal
        v-if="isLogoutModalOpened"
        @close="toggleLogoutModal"
      />
    </transition>
  </div>
</template>

<script>
import SettingsiconSmall from '@/Icons/SettingsiconSmall.vue';
import LogoutIconSmall from '@/Icons/LogoutIconSmall.vue';
import LogoutModal from '@/Components/Auth/LogoutModal.vue';
import UserIcon from '@/Icons/UserIcon.vue';
import { MENU_ITEMS, START_NOW_LINK } from '@/Components/menuItems.js';
import ChatIconSmall from '@/Icons/ChatIconSmall.vue';
import { trackEvent } from '@/Utils/analytics.js';
import userIsAdmin from '@/mixins/userIsAdmin.js';

export default {
  components: {
    ChatIconSmall,
    UserIcon,
    LogoutModal,
    LogoutIconSmall,
    SettingsiconSmall,
  },
  mixins: [userIsAdmin],
  props: {
    isSticky: { type: Boolean, default: false },
  },
  data() {
    return {
      isAuthMenuOpened: false,
      isLogoutModalOpened: false,
      startNowLink: START_NOW_LINK,
    };
  },
  mounted() {
    if (this.isSticky) {
      window.addEventListener('scroll', this.stickyHeader);
    }
  },
  methods: {
    stickyHeader() {
      const header = document.querySelector('.main-header__container');
      const sticky = header?.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
      }

      if (header && window.pageYOffset < 36) {
        header.classList.remove('sticky');
      }
    },
    closeAuthMenu() {
      if (this.isAuthMenuOpened) {
        this.isAuthMenuOpened = false;
      }
    },
    toggleLogoutModal() {
      this.isLogoutModalOpened = !this.isLogoutModalOpened;
      this.toggleBodyScroll();
    },
    toggleBodyScroll() {
      const body = document.querySelector('body');
      let scrollPosition = 0;

      if (this.isBurgerMenuOpened) {
        scrollPosition = window.scrollY;
        body.style.overflow = 'hidden';
        body.style.position = 'fixed';
        body.style.top = `-${scrollPosition}px`;
        body.style.width = '100%';
      } else {
        body.style.removeProperty('overflow');
        body.style.removeProperty('position');
        body.style.removeProperty('top');
        body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
      }
    },
    getStartedButtonClicked() {
      trackEvent('start_page_pressed', { default: this.defaultValue });

      this.$inertia.visit(this.startNowLink);
    },
  },
  computed: {
    showHeaderButton() {
      return this.$page.url !== '/register' && this.$page.url !== '/login';
    },
    user() {
      return this.$page.props.auth.user;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-header__container {
    @apply hidden;

    @screen lg {
        @apply w-full block mx-auto relative z-50;
    }
}

.main-header {
    @apply flex flex-row items-center py-[16px];
}

.main-header__logo {
    @apply flex items-center gap-[12px];
}

.main-header__logo-title {
    @apply font-sans text-[20px] font-bold leading-[24px] tracking-[0px] text-left;
    background: linear-gradient(90deg, #69cbaa 0%, #b39dff 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-header__button {
    @apply relative py-[14px] px-[52px] font-[700] text-[18px] leading-[21px] text-[#0FAB6D];

    &::before {
        @apply w-[198px];
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 18px;
        padding: 1.8px;
        background: linear-gradient(90deg, #0fab6d 0%, #6c3da9 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
}

.main-header__profile-button {
    @apply relative px-[16px] py-[11px] rounded-[16px] flex flex-row items-center gap-[8px] font-sans text-[16px] font-[600px] leading-[24px] tracking-[0px] text-[#0FAB6D];
    border: 1px solid #0fab6d;

    &:hover {
        background-color: rgba(35, 114, 83, 0.2);
    }
}

.chat-header__auth-menu {
    @apply absolute bg-[#191A1B] rounded-[10px] p-[20px];
    top: 60px;
    right: 0;

    &:after {
        content: ' ';
        position: absolute;
        bottom: 100%;
        left: 75%;
        margin-left: -5px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent #191a1b transparent;
    }
}

.settings-container {
    @apply flex flex-row gap-[10px] items-center w-full;

    &:not(:last-child) {
        @apply mb-[15px];
    }
}

.settings-container__title {
    @apply font-sans text-[14px] font-normal leading-[20px] tracking-[0px] text-left uppercase whitespace-nowrap;
}

.sticky {
    .main-header__button {
        @apply rounded-[18px] text-[#FFFFFF];
        box-shadow: 0px 0px 16px 0px #46b192;
        background: linear-gradient(90deg, #0fab6d 0%, #6c3da9 100%);
    }

    @screen lg {
        @apply fixed top-0 left-0 flex items-center justify-center m-auto w-full bg-black;

        .main-header {
            @apply w-[1240px];
        }
    }
}

.sticky + .content {
    @apply pt-[102px];
}
</style>

<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 597 591"
    enable-background="new 0 0 597 591"
    xml:space="preserve"
  >
    <path
      fill="#0E1112"
      opacity="1.000000"
      stroke="none"
      d="
M1.000000,221.000000 
	C1.000000,147.333359 1.000000,74.166710 1.000000,1.000049 
	C199.999924,1.000033 398.999847,1.000033 597.999817,1.000016 
	C597.999878,197.999863 597.999878,394.999725 597.999939,591.999634 
	C399.000214,591.999756 200.000397,591.999756 1.000299,591.999878 
	C1.000000,468.500000 1.000000,345.000000 1.000000,221.000000 
M47.015862,335.541809 
	C39.141937,356.553589 30.169306,377.231171 23.651670,398.655548 
	C13.881601,430.771179 9.436879,463.756165 15.038113,497.345825 
	C19.266407,522.702209 29.841524,544.631531 51.218529,560.146057 
	C70.407120,574.072266 92.487312,578.978210 115.659836,579.979675 
	C147.984680,581.376892 179.347061,575.368530 210.248138,566.730286 
	C265.632355,551.247925 317.854126,527.907227 369.016266,502.015900 
	C369.576660,501.732300 370.043457,501.263702 370.883362,500.634857 
	C368.984009,499.655457 367.423859,498.825409 365.841888,498.039276 
	C351.239594,490.782837 338.190735,481.398743 326.813446,469.693634 
	C306.015656,448.296539 293.222260,422.349792 283.915405,394.409576 
	C273.514038,363.183472 268.274109,330.776611 261.983643,298.609650 
	C257.985321,278.163879 253.300018,257.882141 244.962799,238.704407 
	C241.859604,231.566193 238.197571,224.536377 233.837646,218.102783 
	C216.578110,192.634216 192.404358,185.766418 164.088837,197.831482 
	C146.625107,205.272644 132.075439,216.882370 118.791634,230.129471 
	C88.345451,260.491608 65.823822,296.311066 47.015862,335.541809 
M588.160400,130.472275 
	C587.098816,116.895004 587.290833,103.074997 584.718628,89.790222 
	C579.104248,60.794117 563.758606,38.255863 536.603699,24.894667 
	C520.007996,16.728992 502.234894,13.585152 483.920288,12.977307 
	C450.570160,11.870452 418.320312,18.357752 386.521820,27.518513 
	C332.892090,42.968571 282.191467,65.611290 232.550369,90.797836 
	C231.804108,91.176460 231.200089,91.835449 230.220947,92.606827 
	C268.383881,109.855492 293.374969,139.125137 308.634521,176.673050 
	C316.178894,195.236954 322.023895,214.662811 326.762329,234.154587 
	C333.439331,261.620789 337.926605,289.612366 344.201843,317.185486 
	C348.974335,338.155548 355.490967,358.670471 368.249146,376.494965 
	C384.829376,399.659302 407.483521,406.513184 434.088654,396.315002 
	C454.626099,388.442688 471.088043,374.676422 486.066437,359.078278 
	C518.191589,325.623871 541.733582,286.545776 559.970886,244.161392 
	C575.463562,208.155563 586.448792,170.950882 588.160400,130.472275 
M546.034241,507.561981 
	C557.354614,484.203461 564.887085,459.597046 569.994446,434.218903 
	C578.761902,390.653931 580.486206,346.649902 577.676941,302.416382 
	C576.805908,288.701538 575.272278,275.028748 574.014587,261.031799 
	C573.176941,261.538544 572.752197,261.642120 572.665405,261.872833 
	C572.081482,263.424561 571.533325,264.992035 571.039124,266.574890 
	C556.886536,311.906738 540.970337,356.536926 518.155884,398.386230 
	C503.813141,424.695557 487.250854,449.406250 464.868500,469.689606 
	C441.400330,490.956940 414.519379,503.715027 382.035522,501.907837 
	C378.801758,501.727936 375.579407,501.343109 371.661804,500.991486 
	C382.206879,526.612183 394.779968,549.875244 416.578522,566.789795 
	C426.608887,574.572815 437.860382,579.774170 450.678009,581.064514 
	C471.784149,583.189270 489.504486,575.321106 505.339172,562.262329 
	C523.169617,547.557678 535.496704,528.667419 546.034241,507.561981 
M22.204615,261.421082 
	C22.903053,284.883972 24.132647,308.302521 27.502760,332.111725 
	C28.275536,331.265106 28.598478,331.062012 28.693779,330.781555 
	C29.174826,329.365936 29.594353,327.929443 30.039463,326.501526 
	C43.575790,283.076691 58.755711,240.292679 80.078568,199.956818 
	C95.021713,171.689316 112.286957,145.054703 136.192917,123.362511 
	C159.369507,102.332146 185.911179,89.577583 218.010010,91.051071 
	C221.575821,91.214760 225.130264,91.625885 229.425461,91.983498 
	C218.398590,65.453972 205.502182,41.158909 182.050797,24.471449 
	C160.312943,9.003284 137.161484,7.356562 113.190292,19.234478 
	C101.376503,25.088305 91.497086,33.464424 82.787956,43.246494 
	C63.505566,64.904434 51.130669,90.407936 42.046669,117.627090 
	C26.577085,163.979935 21.877356,211.870865 22.204615,261.421082 
z"
    />
    <path
      fill="#34CF8D"
      opacity="1.000000"
      stroke="none"
      d="
M47.165604,335.194214 
	C65.823822,296.311066 88.345451,260.491608 118.791634,230.129471 
	C132.075439,216.882370 146.625107,205.272644 164.088837,197.831482 
	C192.404358,185.766418 216.578110,192.634216 233.837646,218.102783 
	C238.197571,224.536377 241.859604,231.566193 244.962799,238.704407 
	C253.300018,257.882141 257.985321,278.163879 261.983643,298.609650 
	C268.274109,330.776611 273.514038,363.183472 283.915405,394.409576 
	C293.222260,422.349792 306.015656,448.296539 326.813446,469.693634 
	C338.190735,481.398743 351.239594,490.782837 365.841888,498.039276 
	C367.423859,498.825409 368.984009,499.655457 370.883362,500.634857 
	C370.043457,501.263702 369.576660,501.732300 369.016266,502.015900 
	C317.854126,527.907227 265.632355,551.247925 210.248138,566.730286 
	C179.347061,575.368530 147.984680,581.376892 115.659836,579.979675 
	C92.487312,578.978210 70.407120,574.072266 51.218529,560.146057 
	C29.841524,544.631531 19.266407,522.702209 15.038113,497.345825 
	C9.436879,463.756165 13.881601,430.771179 23.651670,398.655548 
	C30.169306,377.231171 39.141937,356.553589 47.165604,335.194214 
z"
    />
    <path
      fill="#34CF8D"
      opacity="1.000000"
      stroke="none"
      d="
M588.149963,130.966309 
	C586.448792,170.950882 575.463562,208.155563 559.970886,244.161392 
	C541.733582,286.545776 518.191589,325.623871 486.066437,359.078278 
	C471.088043,374.676422 454.626099,388.442688 434.088654,396.315002 
	C407.483521,406.513184 384.829376,399.659302 368.249146,376.494965 
	C355.490967,358.670471 348.974335,338.155548 344.201843,317.185486 
	C337.926605,289.612366 333.439331,261.620789 326.762329,234.154587 
	C322.023895,214.662811 316.178894,195.236954 308.634521,176.673050 
	C293.374969,139.125137 268.383881,109.855492 230.220947,92.606827 
	C231.200089,91.835449 231.804108,91.176460 232.550369,90.797836 
	C282.191467,65.611290 332.892090,42.968571 386.521820,27.518513 
	C418.320312,18.357752 450.570160,11.870452 483.920288,12.977307 
	C502.234894,13.585152 520.007996,16.728992 536.603699,24.894667 
	C563.758606,38.255863 579.104248,60.794117 584.718628,89.790222 
	C587.290833,103.074997 587.098816,116.895004 588.149963,130.966309 
z"
    />
    <path
      fill="#34CE8C"
      opacity="1.000000"
      stroke="none"
      d="
M545.883301,507.904449 
	C535.496704,528.667419 523.169617,547.557678 505.339172,562.262329 
	C489.504486,575.321106 471.784149,583.189270 450.678009,581.064514 
	C437.860382,579.774170 426.608887,574.572815 416.578522,566.789795 
	C394.779968,549.875244 382.206879,526.612183 371.661804,500.991486 
	C375.579407,501.343109 378.801758,501.727936 382.035522,501.907837 
	C414.519379,503.715027 441.400330,490.956940 464.868500,469.689606 
	C487.250854,449.406250 503.813141,424.695557 518.155884,398.386230 
	C540.970337,356.536926 556.886536,311.906738 571.039124,266.574890 
	C571.533325,264.992035 572.081482,263.424561 572.665405,261.872833 
	C572.752197,261.642120 573.176941,261.538544 574.014587,261.031799 
	C575.272278,275.028748 576.805908,288.701538 577.676941,302.416382 
	C580.486206,346.649902 578.761902,390.653931 569.994446,434.218903 
	C564.887085,459.597046 557.354614,484.203461 545.883301,507.904449 
z"
    />
    <path
      fill="#34CE8C"
      opacity="1.000000"
      stroke="none"
      d="
M22.203705,260.930542 
	C21.877356,211.870865 26.577085,163.979935 42.046669,117.627090 
	C51.130669,90.407936 63.505566,64.904434 82.787956,43.246494 
	C91.497086,33.464424 101.376503,25.088305 113.190292,19.234478 
	C137.161484,7.356562 160.312943,9.003284 182.050797,24.471449 
	C205.502182,41.158909 218.398590,65.453972 229.425461,91.983498 
	C225.130264,91.625885 221.575821,91.214760 218.010010,91.051071 
	C185.911179,89.577583 159.369507,102.332146 136.192917,123.362511 
	C112.286957,145.054703 95.021713,171.689316 80.078568,199.956818 
	C58.755711,240.292679 43.575790,283.076691 30.039463,326.501526 
	C29.594353,327.929443 29.174826,329.365936 28.693779,330.781555 
	C28.598478,331.062012 28.275536,331.265106 27.502760,332.111725 
	C24.132647,308.302521 22.903053,284.883972 22.203705,260.930542 
z"
    />
  </svg>
</template>
